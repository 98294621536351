body {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.App {
  width: 100%;
  height: 100%;
  position: fixed;

  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 500px;
  min-width: 400px;
}

.background1 {
  background-color: #FFEE99;
  background-image: url(./res/background1.svg);
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: contain;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100%;
}

.background2 {
  background-color: #FFEE99;
  background-image: url(./res/background2.svg);
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: initial;
  background-size: contain;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100%;
}

.border_black {
  width: 100%;
  height: 100%;
  border-color: black;
  border-style: solid;
  border-radius: 10px;
  border-width: 1px;
}

.border_yellow {
  width: 100%;
  height: 100%;
  border-color: #FFE340;
  border-style: solid;
  border-radius: 10px;
  border-width: 2px;
}

.solid_red {
  background-color: #F7534A;
  color: white;
}

.solid_yellow {
  background-color: #FFE340;
  color: black;
}

.solid_green {
  background-color: #45B382;
  color: white;
}